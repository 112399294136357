
<template>
  <canvas
    ref="chart"
    :width="`${width}px`"
    class="chart"
    :style="{ width: `${width}px` }"
  />
</template>

<script setup>
import { defineProps, ref } from "vue"
import { useIntervalFn } from "@vueuse/core"

const props = defineProps({
  value: {
    type: Number,
    required: true,
  },
  interval: {
    type: Number,
    default: 1000,
  },
  refreshValueInterval: {
    type: Number,
    default: 100,
  },
  maxHistory: {
    type: Number,
    default: 20,
  },
  width: {
    type: Number,
    default: 100,
  },
  color: {
    type: String,
    default: "#78909C",
  },
  lowValueColor: {
    type: String,
    default: "#EF5350",
  },
  lowValueThreshold: {
    type: Number,
    default: 0,
  },

})
const rawValueHistory = ref([])
const valueHistory = ref(Array(props.maxHistory).fill(0))
const chart = ref(null)

useIntervalFn(() => {
  rawValueHistory.value.push(props.value)
}, props.refreshValueInterval)

useIntervalFn(() => {
  const meanRawValue = rawValueHistory.value.reduce((acc, value) => acc + value, 0) / rawValueHistory.value.length
  rawValueHistory.value = []

  valueHistory.value.push(meanRawValue)
  if (valueHistory.value.length > props.maxHistory) {
    valueHistory.value.shift()
  }
  updateChart()
}, props.interval)


const updateChart = () => {
  const height = chart.value.height
  const ctx = chart.value.getContext("2d")
  ctx.clearRect(0, 0, chart.value.width, chart.value.height)

  const barWidth = chart.value.width / valueHistory.value.length
  const barHeight = chart.value.height /
    Math.max(...valueHistory.value, props.lowValueThreshold)

  valueHistory.value.forEach((value, index) => {
    const x = index * barWidth
    const y = height - value * barHeight
    const barColor = value > props.lowValueThreshold ? props.color : props.lowValueColor
    ctx.fillStyle = barColor
    ctx.fillRect(x, y, barWidth, value * barHeight)
  })
}
</script>

<style scoped>
.chart {
  height: 100%;
}
</style>
